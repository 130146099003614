import React from 'react';
import { graphql } from 'gatsby';

import { filterSection } from '../utils/utils';
import { Layout, SEO } from '../components';
import { CountdownTimer } from '../components/UI/FlipClock';
import {
  FeaturedDocuments,
  FAQsSection,
  HeroSection,
  HookSection,
  InfoSection,
  ModulesSection
} from '../sections';
import { HomepageSchema } from '../constants/schemas';

const IndexPage = ({ data: { datoCmsPage: homePage, allDatoCmsDocument: documents, timers } }) => {
  const sectionData = {
    hero: filterSection('customContent', 'hero', homePage),
    info: filterSection('customContent', 'info', homePage),
    hook: filterSection('customContent', 'hook', homePage),
    modules: filterSection('section', 'modules', homePage),
    about: filterSection('section', 'about-author', homePage).section[0],
    faqs: filterSection('section', 'faqs', homePage).section[0],
    featuredDocuments: documents.edges
  };

  sectionData.featuredDocuments.sort((a, b) => {
    const aPageOrder = a.node.isfeaturedonhomepageorder;
    const bPageOrder = b.node.isfeaturedonhomepageorder;
    if (aPageOrder === null) return 1;
    if (bPageOrder === null) return -1;
    return aPageOrder - bPageOrder;
  });

  return (
    <Layout>
      <SEO
        canonicalUrl="https://www.mymathscloud.com"
        schema={HomepageSchema}
        title={homePage.seo.title}
        description={homePage.seo.description}
        image={homePage.seo.image}
        seoKeywords={homePage.seoKeywords}
      />
      <div className="srow centered">
        {timers?.edges?.map(({ node: { endDate, title } }) => (
          <div className="column narrow mb-1">
            <CountdownTimer endDate={endDate} title={title} />
          </div>
        ))}
      </div>
      <HeroSection data={sectionData.hero} />
      <FeaturedDocuments data={sectionData.featuredDocuments} />
      <InfoSection data={sectionData.info} />
      <HookSection data={sectionData.hook} />
      <ModulesSection data={sectionData.modules} />
      <FAQsSection data={sectionData.faqs} />
    </Layout>
  );
};

export default IndexPage;

const query = graphql`
  query IndexQuery {
    datoCmsPage(slug: { eq: "home-page" }) {
      id
      title
      slug
      section {
        name
        section {
          ... on DatoCmsSection {
            id
            title
            subtitle
            description
            image {
              url
              alt
            }
            collapsibleBlock {
              description
              title
            }
          }
          ... on DatoCmsModule {
            id
            title
            slug
            position
            comingSoon
            shortDescription
            description
            image {
              alt
              url
            }
            subCategory {
              name
              slug
            }
          }
        }
      }
      customContent {
        name
        subtitle
        title
        description
        description2
        images {
          alt
          url
        }
      }
      seoKeywords {
        id
        keyword
      }
      seo {
        description
        image {
          url
        }
        title
      }
    }
    allDatoCmsDocument(filter: { isfeaturedonhomepage: { eq: true } }) {
      edges {
        node {
          id
          fileUrl
          hasPassword
          freeSample
          freeDocument
          description
          name
          title
          keywords
          isfeaturedonhomepage
          isfeaturedonhomepageorder
          previewUrl
          fileDescription
          module {
            slug
          }
          documentCategory {
            id
            name
            slug
          }
        }
      }
    }
    timers: allDatoCmsCountdownTimer(filter: { path: { eq: "home-page" } }) {
      edges {
        node {
          title
          endDate
        }
      }
    }
  }
`;
export { query };
